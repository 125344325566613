import * as React from "react"
import { graphql } from 'gatsby'
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Gallery from '@browniebroke/gatsby-image-gallery'

import Layout from "../components/layout"
import Seo from "../components/seo"
import * as styles from "../components/index.module.css"

const IndexPage = ({data}) => {

  console.log(data, 'ddd')
const images = data.allFile.edges.map(({ node }, index) => ({
  ...node.childImageSharp,
  // Generate name based on the index as caption.
  caption: `Image ${index}`,
}))

   // Override some of Lightbox options to localise labels in French
   const lightboxOptions = {
    imageLoadErrorMessage: 'Impossible de charger cette image',
    nextLabel: 'Image suivante',
    prevLabel: 'Image précédente',
    zoomInLabel: 'Zoomer',
    zoomOutLabel: 'Dézoomer',
    closeLabel: 'Fermer',
  }

return <Layout>
<Seo />
<Gallery
    images={images}
    lightboxOptions={lightboxOptions}
  />
  </Layout>

}

export const query = graphql`
  query ImagesForGallery {
    allFile(filter:{relativeDirectory: {eq: "gallery"}}) {
      edges {
        node {
          childImageSharp {
            thumb: gatsbyImageData(
              width: 270
              height: 270
              placeholder: BLURRED
            )
            full: gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`
export default IndexPage
